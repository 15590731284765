.header {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.row-wrapper {
  margin-bottom: 20px;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnContainer {
  display: flex;
  button {
    margin: 20px;
  }
}

.main-btn-container {
  display: flex;
  button {
    margin: 10px;
  }

}
.menulist{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.reclist{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
