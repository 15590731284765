.nomatchPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px;
}
h1{
    font-size: 180px;
}

.icon {
    display: flex;
    justify-content: center;
    border: 3px solid black;
    border-radius: 10px;
    margin: 40px;
    padding: 40px;
}