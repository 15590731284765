.hotkeysListContainer {
  margin: 20px 0 40px 0;
  
  .hotKeysListTable {
    width: 100%;
    
    th {
      padding: 15px 0 5px 0;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    td {
      padding: 15px 5px;
    }
    tr:nth-child(even) {
      background-color: rgb(230, 230, 230);
    }
    
    .shortcut {
      padding-right: 1em;
    }
    
    .shortcutDesc {
      max-width: 300px;
      padding-left: 20px;
    }
    
    .shortcut, .shortcutDesc {
      border: lightgrey 1px solid;
    }
    
    .shortkey {
      min-width: 30px;
      border: 1px solid grey;
      border-radius: 5px;
      margin: 0 3px;
      padding: 0.35em 0.5em 0.5em 0.35em;
      box-shadow: 1px 1px 1px black;
      background: white;
      color: black;
    }
  }
}