.microphone-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  canvas {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid black;
  }
}

@mixin recording-btn-mixin {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(red, .5);
}

.recording-btn {
  @include recording-btn-mixin;
  color: black;
  border: none;
  border: 1px solid black;
  border-radius: 50%;
  background: white;

  &:disabled {
    color: gray;
    border: 1px solid gray;
  }
}

.recording-pulse-btn {
  @include recording-btn-mixin;
  color: white;
  border: none;
  background: red;
  animation: pulse 1.5s infinite;

  &:hover {
    animation: none;
  }

  &:focus {
    outline: none;
  }
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(red, 0);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(red, 0);
  }
}
.temp-hypothese {
  color: lightslategray
}

.results {
  padding: 10px;
  overflow-x: hidden;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(farthest-side, 50% 0,rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side, 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;


  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}