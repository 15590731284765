.appBar {
  margin-bottom: 20px;
}

.list {
  width: 250px
}

.logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 25%;
}

.btn-container {
  position: absolute;
  top: 12%;
  right: 0;
}
