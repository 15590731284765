.unauthPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 60px;
}
h1{
    font-size: 120px;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px solid red;
    border-radius: 10px;
    margin: 30px;
    padding: 20px;

    h1 {
        text-align: center;
        color: red;
        margin: 10px;
    }
}
