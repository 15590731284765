.microphone-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  canvas {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid black;
  }
}

@mixin recording-btn-mixin {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(red, .5);
}

.recording-btn {
  @include recording-btn-mixin;
  color: black;
  border: none;
  border: 1px solid black;
  border-radius: 50%;
  background: white;

  &:disabled {
    color: gray;
    border: 1px solid gray;
  }
}

.recording-pulse-btn {
  @include recording-btn-mixin;
  color: white;
  border: none;
  background: red;
  animation: pulse 1.5s infinite;

  &:hover {
    animation: none;
  }

  &:focus {
    outline: none;
  }
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(red, 0);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(red, 0);
  }
}

.dialog-content {
  min-width: 300px;
}

#oscilloscope-container {
  border-radius: 3px;
  background: white;
  height: 100px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
}

.selectedMicrophoneContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
  color: #aa647b;
}