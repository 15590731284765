.table-wrapper {
    padding: 10px;
}

.list{
    width: 100%;
}

.heading {
    margin: 10px;
}