.input{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.textfield{
    min-width: 200px;
    margin-right: 40px;
}
.btnContainer {
  display: flex;
  justify-content: space-around;
}
.headline {
  display: flex;
  justify-content: space-between;
}
.layout{
  margin: 30px
}
.btn{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
