.root {
  width: '100%';
}

.table {
  min-width: 750px;
}

.row {
  &:hover {
    cursor: pointer;
  }
}