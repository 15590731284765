.userTable{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px;
    padding: 10px;
    border-left: 6px solid lightseagreen;
    justify-content: space-around;
    
}
.buttons{
    width: 33.33%;
    justify-content: center;
    align-items: center;
    display: flex;
    button {
        margin: 10px;
    }
}
.uuid{
    width:  33.33%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.userName{
    width: 33.33%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.wrapper{

}
