.root {
  padding-left: 16px;
  padding-right: 8px;
}

.highlight {
  color: #90caf9;
  background-color: #aa647b;
}

.title {
  flex: '1 1 100%';
}