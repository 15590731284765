.dialog-content {
    min-width: 300px;
}

.btnContainer {
    display: flex;
    justify-content: center;
    button {
      margin: 20px;
    }
  }