.userForm {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .MuiFormControl-root, button {
        margin: 10px 50px;
    }
}

.checkbox {
    display: flex;
    justify-content: center;
}
