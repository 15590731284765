.btnSave{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.translationSwitches{
  margin-left: 5px;
}

.select-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formControl {
  margin: 10px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}