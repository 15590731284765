.SignInContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .SignInBox {
    display: flex;
    width: 500px;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    background: rgb(235, 235, 235);
    border: 1px solid grey;
    border-radius: 15px;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 5px rgb(100, 100, 100);
    h1 {
      font-size: 30px;
      text-align: center;
    }
    >div,>h1 {
      margin: 15px 0;
      width: 100%;
    }
    >button {
      margin: 5px 15px 25px 15px;
    }
    p {
      text-align: center;
      margin: 10px 0;
      
    }
    .SignInButtons {
      display: flex;
      justify-content: space-around;
    }
  }
}