.title{
    text-align: center;
    margin: 20px;
    padding: 20px;
}
.textaudio{
    text-align: center;
}
textarea {
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 50px;
    margin-right: 50px;
    height: 500px;
}
audio{
    width: 100%;
}
.content-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.btn{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
        margin: 10px;
        display: flex;
        flex-wrap: wrap;

        svg {
            margin-right: 10px;
        }
    }
}

.box{
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    border: 2px solid #cccccc;
    height: 140px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.speed{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    background-color: white;
}
.rhap_container {
    box-shadow: none;
}
.selectSpeed{
    margin-left: 10px;
}
.editor{
    margin-top: 50px;
    margin-left: -25px;
    margin-right: -25px;
}
