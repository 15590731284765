.visuallyHidden {
  position: 'absolute';
  top: 20;
  width: 1;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: 'hidden';
  padding: 0;
}